import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const About = () => (
  <Layout>
    <SEO title="About Us" />
    <section className="section">
      <div className="container is-centered">
        <div className="columns">
          <div className="column is-8 is-offset-2">
            <h1 className="is-size-1 has-text-centered">About Us</h1>
            <hr />
            <div className="box">
              Welcome to Waggtopia, your number one source for personalized pet
              products. We're dedicated to offering you the very best. <br />
              <br />
              Founded in 2019, Waggtopia has come a long way from its beginnings
              in Tempe, Arizona. When we first started out, our passion for
              personalized products drove us to creating high quality
              personalized pet products so that we can offer you. <br />
              <br />
              We now serve customers all over the world, and are thrilled that
              we're able to turn our passion into our own website. We hope you
              enjoy our products as much as we enjoy offering them to you.{" "}
              <br />
              <br />
              If you have any questions or comments, please don't hesitate to
              contact us.
              <br />
              <br />
              Sincerely, Waggtopia.
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default About
